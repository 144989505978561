// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Font awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~highlight.js/scss/default.scss';
a {
    text-decoration: none !important;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol {
    font-size: 1.15em;
    li {
        margin-bottom: 8px;
    }
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #18BC9C !important;
}

.h3, h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    margin-top: 20px;

}

.bg-background {
    background-image: url('/images/main-hd.png');
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-background {
    background-image: url('/images/about-hd.png');
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-gray {
    color: #2c3e50 !important;
}

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.navbar-nav-svg {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-top;
}

.blog-masthead {
    padding-top: calc(1rem + 72px);
    padding-bottom: 1rem;
}

.blog-masthead a {
    color: white;
}

.blog-masthead h1 {
    font-size: 3rem;
    line-height: 3rem;
}

.blog-masthead h2 {
    font-size: 1.3rem;
    font-family: 'Lato';
}

article {
    &>h2 {
        margin-top: 30px;
    }
    &>blockquote {
        background: #f9f9f9;
        border-left: 3px solid #ccc;
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        font-style: italic;
        font-size: 1.1rem;
    }
}

@media (min-width: 992px) {
    .blog-masthead {
        padding-top: calc(4rem + 106px);
        padding-bottom: 1rem;
    }
    .blog-masthead h1 {
        font-size: 2.75em;
        line-height: 4rem;
    }
    .blog-masthead h2 {
        font-size: 1.5em;
    }
}

.blog {
    color: #2c3e50!important;
    .blog-list {
        a {
            color: #2c3e50 !important;
        }
        .blog-list-title {
            font-size: 1.5em;
        }
        .blog-description {
            font-size: 1.1em;
        }
    }
    .tags {
        .tag-list {
            list-style: none;
            padding-left: 1em;
        }
    }
}

.post {
    p {
        font-size: 1.3em;
    }
}

.blog-divider {
    margin-bottom: 1.5rem;
    border: 0;
    border-top: 1px solid #ddd;
}

.post-tags {
    list-style: none !important;
    &>.tag-item {
        float: left;
        margin-bottom: 15px;
        &>a {
            color: #fff;
            background-color: #2c3e50;
            padding: 5px;
            margin: 20px 10px;
            border-radius: 3px;
        }
    }
}

.icon {
    width: 256px;
    height: 256px;
    background-color: rgba(24, 188, 156, 0.2);
    border-radius: 50%;
    border: 3px solid #18BC9C;
    & p {
        font-size: 13.7em;
        margin: 0;
        font-style: italic;
        line-height: 0.85;
    }
}

.form-control-fix {
    height: calc(1.5em + 0.75rem + 5px) !important;
}

@include media-breakpoint-down(sm) {
    .icon {
        width: 192px;
        height: 192px;
        & p {
            font-size: 10.2em;
        }
    }
}

@include media-breakpoint-down(xs) {
    .blog-masthead h1 {
        font-size: 2rem;
        line-height: 3rem;
    }
}

pre.ql-syntax {
    background: rgba(238, 238, 238, 0.35);
    border: solid 2px rgba(0, 0, 0, 0.05);
    color: #000;
    overflow-x: auto;
    padding: 0.5em;
    font-size: 100% !important;
}

.share-link {
    color: #128f76 !important;
}

.sidebar-search {
    margin-bottom: 15px;
}

.search {
    margin: 0;
    padding: 15px;
    &>.card {
        border: none;
        background-color: transparent !important;
        &>.card-body {
            padding: 0;
        }
    }
}


/* Our mixin positions a copy of our text
directly on our existing text, while
also setting content to the appropriate
text set in the data-text attribute. */

@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch {
    animation: glitch-skew 5s infinite linear alternate-reverse;
    &::before {
        @include glitchCopy;
        left: 2px;
        text-shadow: -2px 0 #ff00c1;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim 8s infinite linear alternate-reverse;
    }
    &::after {
        @include glitchCopy;
        left: -2px;
        text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
        animation: glitch-anim2 1s infinite linear alternate-reverse;
    }
}

@keyframes glitch-anim {
    $steps: 20;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-skew {
    $steps: 10;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(10) - 5) + deg);
        }
    }
}
